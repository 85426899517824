import { pipe } from '../pipe/pipe';

const expandMonthText = (text: string): string => {
  const macroPattern = '#current_month_name#';
  if (text.includes(macroPattern)) {
    const currentMonthName = new Date().toLocaleString('de-de', {
      month: 'long',
    });
    return text.replace(macroPattern, currentMonthName);
  }

  return text;
};

const expandMonthNumber = (text: string): string => {
  const macroPattern = '#current_month#';
  if (text.includes(macroPattern)) {
    const currentMonth = new Date().toLocaleString('de-de', {
      month: '2-digit',
    });
    return text.replace(macroPattern, currentMonth);
  }

  return text;
};

const expandDate = (text: string): string => {
  const macroPattern = '#current_date#';
  if (text.includes(macroPattern)) {
    const currentDate = new Date().toLocaleString('de-de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return text.replace(macroPattern, currentDate);
  }

  return text;
};

const expandYear = (text: string): string => {
  const macroPattern = '#current_year#';
  if (text.includes(macroPattern)) {
    const currentYear = new Date().toLocaleString('de-de', {
      year: 'numeric',
    });
    return text.replace(macroPattern, currentYear);
  }

  return text;
};

export const expandTextMacros = (text: string | null): string => {
  if (!text) return '';
  return pipe(expandMonthText, expandMonthNumber, expandDate, expandYear)(text);
};
