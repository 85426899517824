import { styled } from '@mui/material/styles';

const StarBackground = styled('div')(({ theme }) => ({
  background: "url('/assets/icons/star_empty.svg') repeat-x",
  width: '120px',
  maxWidth: '120px',
  [theme.breakpoints.down('md')]: {
    backgroundSize: '20px',
    maxWidth: '100px',
  },
}));

const StarImages = styled('div')<{ width: string }>(({ theme, width }) => ({
  background: "url('/assets/icons/star.svg') repeat-x",
  display: 'flex',
  overflow: 'hidden',
  width,
  height: '24px',
  maxWidth: '120px',
  [theme.breakpoints.down('md')]: {
    backgroundSize: '20px',
    maxWidth: '100px',
    '& img': {
      width: '20px',
      height: '20px',
    },
  },
}));

export default function StarRating({ stars }: { stars: number }) {
  return (
    <StarBackground>
      <StarImages width={`${(100 / 5) * stars}%`} role="presentation" />
    </StarBackground>
  );
}
