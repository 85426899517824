import {
  isSessionStorageAccessible,
  isLocalStorageAccessible,
} from './isBrowserStorageAccessible';

// NOTE: data last until window/tab is closed
/**
 * sets value to sessionStorage, and returns boolean
 * @param {string} key
 * @param {string} value
 * @returns {boolean} sets value to sessionStorage, and returns true if sessionStorage was accessible, false if it wasn't
 */
export const setSessionStorage = (key: string, val: string) =>
  isSessionStorageAccessible && (global.sessionStorage.setItem(key, val), true);

/**
 * gets value from sessionStorage or returns null
 * @param {string} key
 * @returns {(null|string)} gets value from sessionStorage or returns null if sessionStorage not accessible or there is no value for that key
 */
export const getSessionStorage = (key: string) =>
  isSessionStorageAccessible ? global.sessionStorage.getItem(key) : null;

// NOTE: data lasts until the app removes them
/**
 * sets value to localStorage and returns boolean
 * @param {string} key
 * @param {string} value
 * @returns {boolean} sets value to localStorage, and returns true if localStorage was accessible, false it wasn't
 */
export const setLocalStorage = (key: string, val: string) =>
  isLocalStorageAccessible && (global.localStorage.setItem(key, val), true);
/**
 * gets value from localStorage or returns null
 * @param {string} key
 * @returns {(null|string)} gets value from localStorage or returns null if localStorage not accessible or there is no value for that key
 */
export const getLocalStorage = (key: string) =>
  isLocalStorageAccessible ? global.localStorage.getItem(key) : null;

/**
 * removes key from local storage (if accessible) and returns boolean which indicates if local storage is accessible
 * @param {string} key
 * @returns {boolean} indicates if local storage is accessible
 */
export const removeLocalStorage = (key: string) =>
  isLocalStorageAccessible && (global.localStorage.removeItem(key), true);

export const removeSessionStorage = (key: string) =>
  isSessionStorageAccessible && (global.sessionStorage.removeItem(key), true);
