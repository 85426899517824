import React, { useState } from 'react';
import Image from 'next/image';
import { styled, useTheme } from '@mui/material/styles';
import {
  useMediaQuery,
  ClickAwayListener,
  Link,
  Typography,
} from '@mui/material';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { getGenericTrackingData } from '@finanzcheck/one-segment';

import { track } from 'lib/tracking/track';
import { TrackingEvent } from 'lib/tracking/events';

import GuaranteeLogo from 'public/assets/icons/guarantee.svg';

const ModalHeadline = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
`;

const ModalText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  margin: `${theme.spacing(2)} 0`,
  minWidth: '250px',
  '& a': {
    fontWeight: 500,
    '&:hover': {
      opacity: '0.75',
    },
  },
}));

const Badge = styled(Image)(({ theme }) => ({
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    width: '100px',
    height: '100px',
  },
}));

export const GuaranteeBadge = ({
  ...otherTooltipProps
}: Partial<TooltipProps>) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTooltipClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (open === false) {
      track(TrackingEvent.GUARANTEE_BADGE_CLICKED, {
        ...getGenericTrackingData(),
      });
    }

    setOpen(prevValue => !prevValue);
  };

  const handleTooltipLinkClick = () => {
    track(TrackingEvent.GUARANTEE_BADGE_LINK_CLICKED, {
      ...getGenericTrackingData(),
    });
    window.open('https://www.smava.de/kredit/guenstiger', '_blank');
  };

  return (
    <>
      {isMobile ? (
        <Tooltip
          sx={{ pointerEvents: 'auto' }}
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: false,
                    tether: true,
                    rootBoundary: 'viewport',
                  },
                },
              ],
            },
          }}
          onClose={() => setOpen(false)}
          open={open}
          disableHoverListener
          disableTouchListener
          leaveDelay={1000}
          arrow
          {...otherTooltipProps}
          title={
            <>
              <div onClick={handleTooltipClick}>
                <ModalHeadline variant="h6">
                  Günstiger-Geht-Nicht-Garantie
                </ModalHeadline>
                <ModalText>
                  Wir überweisen Ihnen eine Einmalzahlung, wenn das günstigste
                  über smava.de gefundene Kreditangebot nicht günstiger sein
                  sollte als ein vergleichbares, nicht über smava.de
                  vermitteltes Kreditangebot einer inländischen Bank
                  (Referenzangebot) und Sie Ihren Kredit trotzdem über smava.de
                  abschließen. Durch die Einmalzahlung unterbieten wir das
                  Referenzangebot.
                </ModalText>
              </div>

              <ModalText>
                Mehr über die Garantie sowie alle Garantie-Bedingungen finden
                Sie{' '}
                <Link href="#" onClick={handleTooltipLinkClick}>
                  hier
                </Link>
                .
              </ModalText>
            </>
          }
        >
          <Badge
            src={GuaranteeLogo.src}
            alt="Günstiger-Geht-Nicht-Garantie Badge"
            height={100}
            width={100}
            priority
            onClick={handleTooltipClick}
          />
        </Tooltip>
      ) : (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Tooltip
            sx={{ pointerEvents: 'auto' }}
            PopperProps={{
              disablePortal: true,
              popperOptions: {
                modifiers: [
                  {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                      altAxis: true,
                      altBoundary: false,
                      tether: true,
                      rootBoundary: 'viewport',
                    },
                  },
                ],
              },
            }}
            onClose={() => setOpen(false)}
            open={open}
            disableFocusListener={false}
            disableHoverListener
            disableTouchListener
            leaveDelay={100}
            arrow
            {...otherTooltipProps}
            title={
              <>
                <ModalHeadline variant="h6">
                  Günstiger-Geht-Nicht-Garantie
                </ModalHeadline>
                <ModalText>
                  Wir überweisen Ihnen eine Einmalzahlung, wenn das günstigste
                  über smava.de gefundene Kreditangebot nicht günstiger sein
                  sollte als ein vergleichbares, nicht über smava.de
                  vermitteltes Kreditangebot einer inländischen Bank
                  (Referenzangebot) und Sie Ihren Kredit trotzdem über smava.de
                  abschließen. Durch die Einmalzahlung unterbieten wir das
                  Referenzangebot.
                </ModalText>

                <ModalText>
                  Mehr über die Garantie sowie alle Garantie-Bedingungen finden
                  Sie{' '}
                  <Link href="#" onClick={handleTooltipLinkClick}>
                    hier
                  </Link>
                  .
                </ModalText>
              </>
            }
          >
            <Badge
              src={GuaranteeLogo.src}
              alt="Günstiger-Geht-Nicht-Garantie Badge"
              height={100}
              width={100}
              priority
              onClick={handleTooltipClick}
            />
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};
