import Head from 'next/head';
import { useRouter } from 'next/router';
import { styled, useTheme } from '@mui/material/styles';

import { steps } from 'legacy/data/stepExplanations';
import { textImageObject } from 'legacy/data/textImageObject';

import type { Testimonial, AverageScore } from 'lib/api-routes/ekomi/types';
import { fetchTestimonials } from 'lib/api-routes/ekomi/fetchTestimonials';
import { fetchAverageScore } from 'lib/api-routes/ekomi/fetchAverageScore';
import { LINKED_DATA_SCHEMA } from 'lib/constants/jsonld';
import { withOptimizelyProps } from 'lib/optimizely';

import { MetaHead } from 'components/MetaHead/MetaHead';
import StepExplanations from 'components/StepExplanations';
import { Testimonials } from 'components/Testimonials';
import TextImage from 'components/TextImage';
import { Stage } from 'components/Stage/Stage';
import { ListIcons, SellingPointsPosition } from 'components/Stage/utils';
import { getStyleOverrides } from 'components/Stage/StyleOverrides/SEAPages';
import { PartnerBanks } from 'components/PartnerBanks/PartnerBanks';
import { TrackButtonApp } from 'components/AppComponents/TrackButton';
import { LegalTextApp } from 'components/AppComponents/LegalText';
import { LegalTextGoogle } from 'components/Stage/LegalTextGoogle';

export const APP_PAGE_URL = '/app-page';

interface PageProps {
  testimonials: Testimonial[];
  averageScore: AverageScore | null;
}

const StageBackground = styled('div')(({ theme }) => ({
  background: `linear-gradient(0deg, #f7f7f7 0%, rgba(247, 247, 247, 0) 100%)`,
  padding: `${theme.spacing(3)} 0 ${theme.spacing(2)}`,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(8)} 0 ${theme.spacing(4)}`,
  },
}));

export default function Home({ testimonials, averageScore }: PageProps) {
  const theme = useTheme();
  const { pathname } = useRouter();
  const canonicalUrl = `https://www.smava.de${pathname}`;

  return (
    <>
      <MetaHead
        follow={true}
        index={true}
        markupSchema={LINKED_DATA_SCHEMA.website}
      />
      <Head>
        {process.env.RELEASE_STAGE === 'live' && (
          <link href={canonicalUrl} rel="canonical" />
        )}
      </Head>

      <StageBackground>
        <Stage
          sellingPoint1="Persönliche Angebote von über 20 Banken"
          sellingPoint2="SCHUFA-neutral und 100% kostenlos"
          sellingPoint3="Deutschlands bekanntestes** Kreditportal"
          sellingPointsPosition={SellingPointsPosition.UP}
          listIcon={ListIcons.CHECK_MARKS}
          averageScore={averageScore}
          styleOverrides={getStyleOverrides(theme)}
          showGuaranteeBadgeOnTop
          showTrustAndEkomi
          isBulletPointsPositionRearranged
          isAppContext
        />
        <LegalTextGoogle />
      </StageBackground>

      <PartnerBanks />

      <Testimonials
        testimonials={testimonials}
        title="Über 300.000 zufriedene Kunden mit smava"
      />
      <StepExplanations
        name="stepExplanations"
        headline="In 3 Schritten zum Wunschkredit"
        steps={steps}
      />
      <TextImage {...textImageObject} />

      <TrackButtonApp />
      <LegalTextApp />
    </>
  );
}

export const getServerSideProps = withOptimizelyProps<PageProps>(async () => {
  const testimonials = await fetchTestimonials(24);
  const averageScore = await fetchAverageScore(365);

  return {
    props: {
      testimonials,
      averageScore,
    },
  };
});
