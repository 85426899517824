import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  OptimizelyContext,
  useDecision as nativeUseDecision,
} from '@optimizely/react-sdk';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { getDecisionDetails } from './parser';
import Bugsnag from '../bugsnag';
import { getCookie } from '../cookies/cookieHelper';
import { forceVariation } from './forceVariation';
import { USER_ID_COOKIE_NAME } from './getUserId';
import { logForDev } from 'lib/logging/devLogger';
import { track } from 'lib/tracking/track';
import { TrackingEvent } from 'lib/tracking/events';

export default function useDecision(
  ...args: Parameters<typeof nativeUseDecision>
): ReturnType<typeof nativeUseDecision> {
  // default to auto-update in order to allow updating user id
  // this is relevant for static pages that navigate to an optimizely-enabled page
  args[1] = { autoUpdate: true, ...args[1] };
  const { optimizely } = useContext(OptimizelyContext);
  const router = useRouter();
  const urlParams = new URLSearchParams(router.query as Record<string, string>);

  forceVariation(urlParams, optimizely);

  const [decision, ...rest] = nativeUseDecision(...args);
  const { isAnalyticsReady } = useAnalytics();
  // TODO refactor, too long
  useEffect(() => {
    // Only track enabled experiments
    if (decision.enabled && isAnalyticsReady && optimizely) {
      try {
        // get properties needed to forward event from Segment to Optimizely
        const datafile = JSON.parse(
          optimizely.getOptimizelyConfig()?.getDatafile() || '{}',
        );
        const decisionDetails = getDecisionDetails(datafile, {
          ruleKey: decision.ruleKey!,
          variationKey: decision.variationKey!,
        });
        let eventProperties: { [k: string]: any } = {
          experiment_name: decision.ruleKey,
          variation_name: decision.variationKey,
          ...decisionDetails,
        };
        const optimizelyUserId = getCookie(USER_ID_COOKIE_NAME);
        if (!optimizelyUserId) {
          throw new Error('Optimizely user id not found');
        }

        const optimizelyContext: any = { userId: optimizelyUserId };
        if (optimizely?.user?.attributes) {
          optimizelyContext['userAttributes'] = optimizely.user.attributes;
        } else {
          logForDev('Optimizely: no user attributes');
          Bugsnag.notify(new Error('no attributes for Optimizely user'));
        }

        track(TrackingEvent.OPTIMIZELY_EXPERIMENT_VIEWED, eventProperties, {
          context: { Optimizely: optimizelyContext },
        });
      } catch (e: any) {
        logForDev('error on decision event: ', e);
        Bugsnag.notify(e);
      }
    }
  }, [decision, isAnalyticsReady, optimizely]);

  return [decision, ...rest];
}
