import React from 'react';

type AnalyticsContextProps = {
  isAnalyticsReady?: boolean;
  setIsAnalyticsReady?: (props: boolean) => void;
};

/**
 * Create the context itself that is used within the
 * consumer and provider
 */
const AnalyticsContext = React.createContext<AnalyticsContextProps>({});

/**
 * A convenience wrapper for the AnalyticsProvider
 */
const AnalyticsProvider = AnalyticsContext.Provider;

/**
 * A convenience wrapper for the AnalyticsConsumer
 */
const AnalyticsConsumer = AnalyticsContext.Consumer;

/**
 * useLoanSelection Hook for easy use within functional components
 */
function useAnalytics() {
  return React.useContext(AnalyticsContext);
}

export { AnalyticsProvider, AnalyticsConsumer, useAnalytics };
