import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import {
  CATEGORY_OPTIONS,
  DEFAULT_SELECTION_VALUES,
  DURATION_OPTIONS,
} from 'lib/offers/filters';
import { SMAVA_CATEGORY } from 'lib/urlParamsConverter/categories';


interface IFormValues {
  amount: number;
  category: number;
  duration: number;
  vehiclePrice: number;
  initialPayment: number;
}

function isValidPosInteger(
  value: string | string[] | undefined | number | null,
) {
  const parsedValue = parseToInt(value);
  if (parsedValue >= 0) return true;

  return false;
}

function parseToInt(value: string | string[] | undefined | number | null) {
  const stringValue = String(value);
  const parsedValue = parseInt(stringValue);

  return parsedValue;
}

function parseQueryParam(
  value: string | string[] | undefined,
  defaultValue: number,
  options?: number[] | SMAVA_CATEGORY[],
) {
  if (isValidPosInteger(value)) {
    const parsedToIntValue = parseToInt(value);

    if (!options || (options && options.includes(parsedToIntValue))) {
      return parsedToIntValue;
    }
  }

  return defaultValue;
}

function getLoanSelectionFromQueryParams(
  queryParams: NextParsedUrlQuery,
): IFormValues {
  const parsedQueryParams = {
    amount: parseQueryParam(
      queryParams.amount,
      DEFAULT_SELECTION_VALUES.amount,
    ),
    category: parseQueryParam(
      queryParams.category,
      DEFAULT_SELECTION_VALUES.category,
      CATEGORY_OPTIONS.map((option) => option.value),
    ),
    duration: parseQueryParam(
      queryParams.duration,
      DEFAULT_SELECTION_VALUES.duration,
      DURATION_OPTIONS,
    ),
    vehiclePrice: parseQueryParam(
      queryParams.vehiclePrice,
      DEFAULT_SELECTION_VALUES.vehiclePrice,
    ),
    initialPayment: parseQueryParam(
      queryParams.initialPayment,
      DEFAULT_SELECTION_VALUES.initialPayment,
    ),
  };

  return parsedQueryParams;
}

const isSEAPage = (pathName: string) => {
  if (pathName.indexOf('lp') > -1) {
    return true;
  }
  return false;
};

export {
  getLoanSelectionFromQueryParams,
  parseToInt,
  isValidPosInteger,
  isSEAPage,
};
