//* This file is used to define helper functions for JSON operations

/**
 * Strip undefined values from an object about to be serialized to JSON
 * @param obj
 * @returns
 */
export const stripUndefinedFromObject = (obj: Record<string, any>) => {
  let newObj: Record<string, any> = {}; // Add index signature to newObj
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key]))
      newObj[key] = stripUndefinedFromObject(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};
