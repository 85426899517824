import { STREET, ZIP, CITY, PHONE } from 'lib/constants/address';
import { LINKED_DATA_SCHEMA } from 'lib/constants/jsonld';

// These helpers are for the JSON-LD scripts in the Head component
// They are structured meta data for search engines which helps our SEO
// Tester: https://developers.google.com/search/docs/appearance/structured-data

export const getJsonLDsForSchema = (schema: string) => {
  switch (schema) {
    case LINKED_DATA_SCHEMA.article:
      return {
        organization: true,
        website: true,
        breadcrumb: true,
        article: true,
        linkedData: true,
      };
    case LINKED_DATA_SCHEMA.website:
      return {
        organization: true,
        website: true,
        breadcrumb: false,
        article: false,
        linkedData: true,
      };
    default:
      return {
        organization: false,
        website: false,
        breadcrumb: false,
        article: false,
        linkedData: false,
      };
  }
};

// Used for AuthorBox
export const getPersonJsonLD = (
  personName: string,
  imageUrl: string,
  imageTitle: string,
  socialMediaUrls: string[],
) => {
  const personNameForId = personName.trim().toLowerCase();
  const imageTitleForId = imageTitle.trim().toLowerCase();

  return {
    __html: `{
    "@context": "https://schema.org",
    "@type": "Person",
    "@id": "https://www.smava.de/#/person/${personNameForId}",
    "name": "${personName}",
    "image": {
      "@type": "ImageObject",
      "@id": "https://www.smava.de/#/image/${imageTitleForId}",
      "url": "https:${imageUrl}",
      "caption": "${personName}"
    },
    "sameAs": [
      ${socialMediaUrls.map((url) => `"${url}"`).join(',')}
    ]
  }`,
  };
};

export const getOrganizationJsonLD = () => {
  return {
    __html: `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://www.smava.de/#organization",
    "name": "smava",
    "legalName": "smava GmbH",
    "url": "https://www.smava.de",
    "description": "SMAVA Kredite vergleichen: ☎ Telefonische Expertenberatung ✓ Exklusive Kreditangebote ✓ Schufaneutral ✓ Schnell und unkompliziert ➤ Zum günstigen Kredit!",
    "telephone": "${PHONE}",
    "email": "info@smava.de",
    "sameAs": [
        "https://www.facebook.com/people/smava/100064534726416/",
        "https://twitter.com/smava",
        "https://www.youtube.com/@smava.",
        "https://www.xing.com/pages/smavagmbh",
        "https://www.instagram.com/smava_gmbh/",
        "https://www.linkedin.com/company/smava/"
    ],
    "address": {
        "@type": "PostalAddress",
        "@id": "https://www.smava.de/#address",
        "postalCode": "${ZIP}",
        "addressLocality": "${CITY}",
        "addressRegion": "${CITY}",
        "streetAddress": "${STREET}",
        "addressCountry": "DE"
    },
    "logo": {
        "@type": "ImageObject",
        "@id": "https://www.smava.de/#logo",
        "url": "https://www.smava.de/assets/images/json-ld/smava_logo.svg",
        "width": 147,
        "height": 32,
        "caption": "smava"
    }
  }`,
  };
};

export const getWebsiteJsonLD = () => {
  return {
    __html: `{
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://www.smava.de/#website",
    "url": "https://www.smava.de",
    "name": "smava",
    "publisher": {
        "@id": "https://www.smava.de/#organization"
    }
  }`,
  };
};

export const getBreadcrumbJsonLD = (url: string) => {
  const items = url.split('/').filter((part) => part !== '');

  return {
    __html: `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "@id": "https://www.smava.de${url}#breadcrumb",
    "itemListElement": [
  ${items
    .map((item, index) => {
      const url = items.slice(0, index + 1).join('/');
      const name = item.replace(/-/g, ' ');

      return `     {
        "@type": "ListItem",
        "position": ${index + 1},
        "item": {
          "@type": "WebPage",
          "@id": "https://www.smava.de/${url}/",
          "url": "https://www.smava.de/${url}/",
          "name": "${name.toUpperCase()}"
        }
      }`;
    })
    .join(',')}
    ]
  }`,
  };
};

export const getArticleJsonLD = (
  url: string,
  articleSection: string,
  headline: string,
  authorName: string,
  datePublished: string,
  dateModified: string,
) => {
  const capitalizedArticleSection =
    articleSection.charAt(0).toUpperCase() + articleSection.slice(1);
  return {
    __html: `{
    "@type": "Article",
    "@id": "https://www.smava.de${url}#article",
    "isPartOf": {
        "@id": "https://www.smava.de${url}#webpage"
    },
    "datePublished": "${datePublished}",
    "dateModified": "${dateModified}",
    "mainEntityOfPage": {
        "@id": "https://www.smava.de${url}#webpage"
    },
    "publisher": {
        "@id": "https://www.smava.de/#organization"
    },
    "image": {
        "@id": "https://www.smava.de${url}#primaryimage"
    },
    "articleSection": "${capitalizedArticleSection}",
    "headline": "${headline}",
    "author": {
        "@id": "https://www.smava.de/#/person/${authorName.toLocaleLowerCase()}"
    }
  }`,
  };
};
