export const ANDROID = 'android';
export const IOS = 'iOS';
export const WINDOWS_PHONE = 'Windows Phone';
export const DESKTOP = 'Desktop OS';

enum OsName {
  ANDROID = 'android',
  IOS = 'iOS',
  WINDOWS_PHONE = 'Windows Phone',
  DESKTOP = 'Desktop OS',
}

export const getResolvedPathnameFromRouterAsPath = (routerAsPath: string) => {
  const pathname = routerAsPath.split('?')[0];
  return pathname;
};

export const getOsName = (): OsName | string => {
  if (typeof window === 'undefined') {
    return 'unknown';
  }

  const userAgent = window?.navigator?.userAgent;

  if (!userAgent) {
    return 'userAgent not available';
  }

  // Windows Phone must come first because its UA also contains "Android"
  // We do not offer Windows Phone app, so we can ignore it
  if (/windows phone/i.test(userAgent)) {
    return OsName.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return OsName.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return OsName.IOS;
  }

  return OsName.DESKTOP;
};
