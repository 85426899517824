import { styled } from '@mui/material/styles';
import { LegalTextGoogleFooter } from 'components/Stage/LegalText';

const LegalTextContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
}));

export const LegalTextApp: React.FC = () => (
  <LegalTextContainer>
    <LegalTextGoogleFooter />
  </LegalTextContainer>
);
