import { Box, Typography } from '@mui/material';

const Separator = () => (
  <Box
    display={{ xs: 'flex', md: 'none' }}
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    width="100%"
    my={(theme) => theme.spacing(3)}
  >
    <Box
      flexGrow={1}
      height="1px"
      bgcolor={(theme) => theme.palette.grey[300]}
    />
    <Box mx={(theme) => theme.spacing(4)}>
      <Typography variant="subtitle2">oder</Typography>
    </Box>
    <Box
      flexGrow={1}
      height="1px"
      bgcolor={(theme) => theme.palette.grey[300]}
    />
  </Box>
);

export { Separator };
