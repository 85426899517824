import cookie from 'cookie';
import { GetServerSidePropsContext } from 'next';
import { generateSessionId } from 'lib/session/sessionHelper';
import {
  DEFAULT_COOKIE_OPTIONS,
  ONE_YEAR_IN_SECONDS,
} from 'lib/constants/cookies';

export const USER_ID_COOKIE_NAME = 'FCID';

export default function getOptimizelyUserId(ctx: GetServerSidePropsContext) {
  const { req, res } = ctx;

  // The cookie is http only, so we only can read/modify it by changing the requests
  const cookies = cookie.parse(req.headers.cookie ?? '');
  const userIdFromCookie = cookies[USER_ID_COOKIE_NAME];

  // generate an id by using the one read before or generating a new one
  const userId = userIdFromCookie ? userIdFromCookie : generateSessionId();

  // Set the cookie with a userid anew
  res.setHeader(
    'Set-Cookie',
    cookie.serialize(USER_ID_COOKIE_NAME, userId, {
      ...DEFAULT_COOKIE_OPTIONS,
      maxAge: ONE_YEAR_IN_SECONDS,
    }),
  );

  return userId;
}
