import { postPaasData } from './fetchHelper/postPaasData';
import { encodePaasData } from './encodePaasData';
import { isCreditCardCategory, isSolarPanelCategory } from 'lib/baufi/helper';
import { FINANZCHECK_PURPOSE } from 'lib/offers/filters';

export const ensureErrorFieldName = (errors: any) =>
  errors.map((error: any) => error.fieldName || 'unknownField');

export const fetchPaas = async (data: any) => {
  const encodedData = encodePaasData(data);

  if (!encodedData) return false;

  if (
    isCreditCardCategory(encodedData.data.common.purpose) ||
    isSolarPanelCategory(encodedData.data.common.purpose)
  ) {
    encodedData.data.common.purpose = FINANZCHECK_PURPOSE.OTHER;
  }

  try {
    const paasResponse = await postPaasData(encodedData);

    if (!paasResponse) {
      return false;
    }

    const errors = ensureErrorFieldName(paasResponse.errors);

    return {
      ...paasResponse,
      errors,
      hasError: Boolean(errors.length),
    };
  } catch (e) {
    // last ditch and circumvent unhandled promise rejection
    return false;
  }
};
