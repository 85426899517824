import { useRouter } from 'next/router';

import { getGenericTrackingData } from '@finanzcheck/one-segment';
import Button, { ButtonProps } from '@mui/material/Button';
import { TrackingEvent } from 'lib/tracking/events';
import { trackButtonClick } from 'lib/tracking/track';

export type TrackButtonProps = {
  trackingEvent: TrackingEvent;
} & ButtonProps

export const TrackButton = ({ children, onClick, trackingEvent, ...rest }: TrackButtonProps) => {
  const { pathname } = useRouter();
  const handleClick = (e: any) => {
    trackButtonClick(trackingEvent, {
      ...getGenericTrackingData(),
      buttonName: children?.toString().replace('[object Object],', '').trim(),
      buttonLocation: pathname,
    });
    onClick?.(e);
  };

  return (
    <Button onClick={handleClick} {...rest}>
      {children}
    </Button>
  );
};
