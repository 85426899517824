export enum ListIcons {
    CHECK_MARKS,
    DOTS,
    NONE
};

export enum SellingPointsPosition {
    UP,
    DOWN,
    NONE
};
