import React from 'react';
import Image from 'next/image';
import MobileStores from 'public/assets/visuals/app-play-store.svg';
import MobileStores800 from 'public/assets/visuals/app-play-store-grey800.svg';
import AppStoreBlack from 'public/assets/visuals/app-app-store-black.svg';
import GooglePlayBlack from 'public/assets/visuals/app-google-play-black.svg';
import { Box } from '@mui/material';

interface MobileStoresBadgesProps {
  type?: 'black' | 'grey800' | 'default';
}

const MobileStoresBadges: React.FC<MobileStoresBadgesProps> = (props) => {
  if (props.type === 'black') {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        columnGap={3}
      >
        <Image src={GooglePlayBlack} alt="Google Play" />
        <Image src={AppStoreBlack} alt="App Store" />
      </Box>
    );
  }

  const src = props.type === 'grey800' ? MobileStores800 : MobileStores;

  return (
    <Image src={src} alt="Google Play | App Store" style={{ width: '100%' }} />
  );
};

export { MobileStoresBadges };
