import { SMAVA_CATEGORY } from 'lib/offers/filters';

const MAX_LIVING_AMOUNT = 120000;
const MAX_MORTGAGE_AMOUNT = 75000;
const MAX_CREDIT_CARD_AMOUNT_WITH_BAUFI_REDIRECT = 20000;

export function isLivingCategory(category: number | string) {
  return String(category) === String(SMAVA_CATEGORY.LIVING);
}

export function isMortgageCategory(category: number | string) {
  return String(category) === String(SMAVA_CATEGORY.MORTGAGE);
}

export function isCreditCardCategory(category: number | string) {
  return String(category) === String(SMAVA_CATEGORY.CREDIT_CARD);
}

export function isSolarPanelCategory(category: number | string) {
  return String(category) === String(SMAVA_CATEGORY.SOLAR_PANEL);
}

export function isBaufiCreditCardRedirect(
  category: number | string,
  amountAsInt: number,
) {
  return (
    isCreditCardCategory(category) &&
    amountAsInt <= MAX_CREDIT_CARD_AMOUNT_WITH_BAUFI_REDIRECT
  );
}

/**
 * Returns true if this indicates a redirect to baufi partnership page
 */
export function isBaufiRedirect(
  category: number | string,
  amount: number | string,
) {
  const amountAsInt = typeof amount === 'number' ? amount : parseInt(amount);
  return (
    (isLivingCategory(category) && amountAsInt > MAX_LIVING_AMOUNT) ||
    (isMortgageCategory(category) && amountAsInt > MAX_MORTGAGE_AMOUNT) ||
    isBaufiCreditCardRedirect(category, amountAsInt)
  );
}

export function getBaufiUrlForRedirect(
  amount: number,
  category: number,
  duration: number,
  initialPayment: number,
  urlParams: URLSearchParams,
) {
  const urlSearchParams = new URLSearchParams(urlParams);

  const categoryParam = isCreditCardCategory(category)
    ? String(SMAVA_CATEGORY.MORTGAGE)
    : category.toString();

  const addParams = {
    amount: amount.toString(),
    category: categoryParam,
    duration: duration.toString(),
    header_variant: 'none',
    initialPayment: initialPayment.toString(),
    isMortgage: isMortgageCategory(category).toString(),
    route: 'LeadStep2Kreditnehmer',
  };
  for (const [key, value] of Object.entries(addParams)) {
    urlSearchParams.set(key, value);
  }

  const creditCardUrlFlag = isCreditCardCategory(category) ? 'kreditkarte' : '';

  return `https://baufi.smava.de/${creditCardUrlFlag}?${urlSearchParams.toString()}`;
}
