import cookie from 'cookie';

export const ONE_YEAR_IN_SECONDS = 365 * 24 * 60 * 60;
export const COOKIE_BASE_URL = process.env.COOKIE_BASE_URL;

export const DEFAULT_COOKIE_OPTIONS = {
  sameSite: 'none',
  secure: true,
  httpOnly: false,
  path: '/',
  domain: COOKIE_BASE_URL,
} as cookie.CookieSerializeOptions;
