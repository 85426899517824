import { EventTags } from '@optimizely/optimizely-sdk'
import { TrackingEvent } from 'lib/tracking/events';
import { track } from 'lib/tracking/track';

export type OptimizelyEventTag = {
    key: string;
    value: string | number | null;
}

export const tagEvent = (eventName: TrackingEvent, userId: string, value?: number, revenue?: number, tags?: OptimizelyEventTag[]) => {
    const mappedTags = tags?.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
    }, {} as EventTags);
    track(eventName, {
        ...(value && { value }),
        ...(revenue && { revenue }),
        ...(tags && { tags: mappedTags } )
    }, {
        context: {
            Optimizely: {
                userId,
                eventName
            }
        }
    })
}
