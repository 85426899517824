const TEST_ITEM = 'testItem';
export const isSessionStorageAccessible = (() => {
  try {
    if (global.sessionStorage) {
      global.sessionStorage.setItem(TEST_ITEM, TEST_ITEM);
      global.sessionStorage.getItem(TEST_ITEM);
      global.sessionStorage.removeItem(TEST_ITEM);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
})();

export const isLocalStorageAccessible = (() => {
  try {
    if (global.localStorage) {
      global.localStorage.setItem(TEST_ITEM, TEST_ITEM);
      global.localStorage.getItem(TEST_ITEM);
      global.localStorage.removeItem(TEST_ITEM);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
})();
