import { getParser } from 'bowser';
import { customAttributes } from './CustomAttributes';

/**
 *
 * @param userAgent
 * @param url path+query of the url. Think HTTP verb url.
 */
export default function getOptimizelyAttributes(
  userAgent: string,
  url: string,
) {
  if (!userAgent) {
    throw new Error('user agent empty');
  }

  const browser = getParser(userAgent);
  const attributes: { [k: string]: string } = {
    browser: browser.getBrowserName(),
    browserVersion: browser.getBrowserVersion(),
    device: browser.getPlatformType(),
    deviceOS: browser.getOSName(),
    deviceOSVersion: browser.getOSVersion(),
  };

  const utmParameters = [
    'utm_source',
    'utm_medium',
    'utm_content',
    'utm_campaign',
  ];

  const urlObject = new URL(url, 'https://smava.de');
  attributes.path = urlObject.pathname;

  for (const parameterKey of utmParameters) {
    if (urlObject.searchParams.has(parameterKey)) {
      attributes[parameterKey] = urlObject.searchParams.get(parameterKey) ?? '';
    }
  }

  for (const key of customAttributes.keys()) {
    const value = urlObject.searchParams.get(key) ?? customAttributes.get(key);
    if (value) {
      attributes[key] = value;
    }
  }

  return omitEmptyProperties(attributes);
}

function omitEmptyProperties(o: any): any {
  o = { ...o };
  Object.keys(o).forEach((k) => {
    if (!o[k]) {
      delete o[k];
    }
  });
  return o;
}
