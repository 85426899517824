/**
 * Renders the AlternativeCTAButton component.
 * It's a component used by every variation of the App Redirection Experiment CAT-3151.
 *
 * @component
 * @example
 * return (
 *   <AlternativeCTAButton />
 * )
 */
import React from 'react';
import { Box, Button } from '@mui/material';
import { ButtonName, redirectToCorrectStore } from './helpers';

interface Props {
  text: string;
  endIcon?: React.ReactNode;
  variant?: 'outlined' | 'contained';
}

const AlternativeCTAButton: React.FC<Props> = (props) => {
  const handleClick = () => {
    redirectToCorrectStore(ButtonName.BUTTON_DOUBLE_CTA_ALTERNATIVE);
  };

  return (
    <Box
      display={{ xs: 'block', md: 'none' }}
      bgcolor={(theme) => theme.palette.common.white}
    >
      <Button
        data-test-id="CTA"
        variant={props.variant || 'outlined'}
        color="primary"
        type="button"
        fullWidth
        endIcon={props.endIcon}
        sx={{
          minHeight: 48,
        }}
        onClick={handleClick}
      >
        {props.text}
      </Button>
    </Box>
  );
};

export { AlternativeCTAButton };
