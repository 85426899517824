import { styled } from '@mui/material/styles';

import { GuaranteeBadge } from './GuaranteeBadge';
import { CircleBadge, BadgeVariants } from './CircleBadge';
import { BlackFridayBadge } from './BlackFridayBadge';

const Wrapper = styled('div')`
  position: relative;
  top: 0px;
  right: 2px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: -35px 0px 0px -60px;
`;

const BadgeWrapper = styled('div')`
  margin-bottom: 8px;

  :nth-last-child(1) {
    margin: 0;
  }

  img {
    display: block;
    width: 100px;
    height: 100px;
    margin-left: 10px;
  }
`;

const HideSmallerMedium = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: 0,
    display: 'none',
  },
}));

const CircleBadgeWrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '15px 0 0',
  width: '85px',
  height: '85px',
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: '-40px',
    right: '-61px',
    transform: 'scale(0.9)',
  },
}));

export const Badges: React.FC<{
  variant: BadgeVariants;
  isBlackFridayBadgeShown?: boolean;
}> = ({ variant, isBlackFridayBadgeShown }) => {
  const isCircleBadgeVisible = variant !== BadgeVariants.Hidden;

  return (
    <Wrapper>
      {isCircleBadgeVisible && (
        <CircleBadgeWrapper>
          <CircleBadge variant={variant} />
        </CircleBadgeWrapper>
      )}
      <HideSmallerMedium>
        <BadgeWrapper>
          <GuaranteeBadge />
        </BadgeWrapper>
        {isBlackFridayBadgeShown && (
          <BadgeWrapper>
            <BlackFridayBadge />
          </BadgeWrapper>
        )}
      </HideSmallerMedium>
    </Wrapper>
  );
};
