export const STORAGE_CONSTANTS = {
  kuzaFormData: 'KUZA_FORM_DATA',
  actionVoucherCode: 'SMAVA_VOUCHER_CODE',
  actionVoucherCampaign: 'SMAVA_VOUCHER_CAMPAIGN',
  currentKuzaResumeHash: 'CURRENT_KUZA_RESUME_HASH',
  iModCurrentNavigation: 'IMOD_CURRENT_NAVIGATION',
  iModCurrentTraversalData: 'IMOD_CURRENT_TRAVERSAL_DATA',
  browserId: 'BROWSER_ID',
  refId: 'REF_ID',
  backUrl: 'BACK_URL',
  iframeUrl: 'IFRAME_URL',
  isAppOverlayClosed: 'IS_APP_OVERLAY_CLOSED',
};
