function range(start: number, end: number, step: number) {
  let index = -1;
  let length = Math.max(Math.ceil((end - start) / (step || 1)), 0);
  const result = new Array(length);

  while (length--) {
    result[++index] = start;
    start += step;
  }
  return result;
}
export function addDotInAmount(amount: number): string {
  return amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
}

export function removeDotInAmount(amount: string | number): number | string {
  if (amount && typeof amount === 'string') {
    const number = Number(amount.split('.').join(''));
    if (!isNaN(number)) {
      return number;
    }
  }
  return amount;
}

export const MORTGAGE_AMOUNT = 300000;
export const CREDIT_CARD_AMOUNT = 5000;
export const MAX_CREDIT_AMOUNT = 120000;
export const MAX_MORTGAGE_AMOUNT = 5000000;
export const MIN_CREDIT_AMOUNT = 500;
export const MIN_DURATION = 12;
export const MAX_DURATION = 120;

export const DURATION_OPTIONS: number[] = range(
  MIN_DURATION,
  MAX_DURATION + 1,
  12,
);
export const AMOUNT_OPTIONS = range(MIN_CREDIT_AMOUNT, 15000 + 1, 250).concat(
  range(16000, MAX_CREDIT_AMOUNT + 1, 1000),
);

export const AMOUNT_OPTIONS_HIGH_RANGE = range(
  MIN_CREDIT_AMOUNT,
  15000 + 1,
  250,
)
  .concat(range(16000, MAX_CREDIT_AMOUNT + 1, 1000))
  .concat(range(130000, 400000 + 1, 10000))
  .concat(range(420000, 500000 + 1, 20000))
  .concat(range(550000, 600000 + 1, 50000))
  .concat(range(700000, 1000000 + 1, 100000))
  .concat(range(2000000, MAX_MORTGAGE_AMOUNT + 1, 1000000));

/*
 * smava wording below
 */

export enum LABEL {
  Amount = 'Nettokreditbetrag',
  Category = 'Verwendung',
  Description = 'Nachricht',
  Duration = 'Laufzeit',
  InitialPayment = 'Anzahlung €',
  Subject = 'Betreff',
  Text = 'Nachricht',
  VehiclePrice = 'Kaufpreis €',
  'Category.8992946' = 'Umschuldung',
  'Category.887' = 'Auto / Motorrad',
  'Category.886' = 'Wohnen / Modernisierung',
  'Category.40895887' = 'Gewerbe',
  'Category.888' = 'Freie Verwendung',
  'Category.999999999' = 'Bau-/ Immobilienfinanzierung',
  'Category.889' = 'Kreditkarte',
  'Category.900' = 'Solar oder Wärmepumpe Finanzierung',
}

export enum SMAVA_CATEGORY {
  FREE_USE = 888,
  CAR = 887,
  LIVING = 886,
  MORTGAGE = 999999999,
  RESCHEDULE = 8992946,
  BUSINESS = 40895887,
  CREDIT_CARD = 889,
  SOLAR_PANEL = 900,
}

export const DEFAULT_SELECTION_VALUES = Object.freeze({
  amount: 20000,
  duration: 84,
  category: SMAVA_CATEGORY.FREE_USE,
  vehiclePrice: 20000,
  initialPayment: 0,
});

export const SELECTION_FIELDS = Object.keys(DEFAULT_SELECTION_VALUES);

export const getCategoryOptions = (
  isCreditCardCategoryEnabled?: boolean,
  isSolarPanelCategoryEnabled?: boolean,
) =>
  CATEGORY_OPTIONS.filter((categoryOption) => {
    if (
      !isCreditCardCategoryEnabled &&
      categoryOption.value === SMAVA_CATEGORY.CREDIT_CARD
    ) {
      return false;
    }
    if (
      !isSolarPanelCategoryEnabled &&
      categoryOption.value === SMAVA_CATEGORY.SOLAR_PANEL
    ) {
      return false;
    }
    return true;
  });

export const CATEGORY_OPTIONS = Object.keys(SMAVA_CATEGORY).reduce(
  (acc, key) => {
    const value = SMAVA_CATEGORY[key as keyof typeof SMAVA_CATEGORY];
    const labelKey: keyof typeof LABEL = `Category.${value}`;
    const label = LABEL[labelKey];
    if (label) {
      acc.push({
        value,
        label: LABEL[labelKey],
      });
    }
    return acc;
  },
  [] as { value: SMAVA_CATEGORY; label: string }[],
);

/*
 * finanzcheck wording below
 */

export enum FINANZCHECK_PURPOSE {
  REFINANCING = 'REFINANCING',
  PRE_OWNED_CAR = 'PRE_OWNED_CAR',
  RENOVATION = 'RENOVATION',
  REAL_ESTATE_FINANCING = 'REAL_ESTATE_FINANCING',
  OTHER = 'OTHER',
  OTHER_GEWERBE = 'OTHER',
}
