/**
 * Renders the VideoHeroSection component.
 * This component displays a hero section with an app download button.
 * It's a Variation E of the AppRedirection ABCD Test CAT-3151.
 *
 * @component
 * @example
 * return (
 *   <VideoHeroSection />
 * )
 */
import React from 'react';
import { ArrowOutward } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { APP_BUTTON_TEXT, ButtonName, redirectToCorrectStore } from './helpers';
import { MobileStoresBadges } from './MobileStoresBadges';
import { Separator } from './Separator';

const VideoHeroSection: React.FC = () => {
  return (
    <>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        mt={(theme) => theme.spacing(2)}
        flexDirection="column"
        alignItems="center"
      >
        <Box mt={(theme) => theme.spacing(2)}>
          <video
            src={'/assets/videos/app-video.mp4'}
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline
            style={{ width: '100%' }}
          />
        </Box>
        <Box mb={(theme) => theme.spacing(2)} width="100%">
          <Button
            data-test-id="CTA"
            variant="contained"
            fullWidth
            color="primary"
            type="button"
            onClick={() => redirectToCorrectStore(ButtonName.BUTTON_VIDEO)}
            endIcon={<ArrowOutward />}
          >
            {APP_BUTTON_TEXT}
          </Button>
        </Box>

        <MobileStoresBadges type="black" />

        <Separator />
      </Box>
    </>
  );
};

export { VideoHeroSection };
