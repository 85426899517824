export function eventNameFromPath(url: string): string {
  if (!url) url = '';
  if (url.substring(0, 1) === '/') url = url.slice(1);
  url = url.replace(/\//, '_');
  url = url.replace(/[^a-zA-Z\d_\-]/, '');

  if (!url) {
    return 'home';
  }
  return url;
}
