import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import type { AverageScore } from 'lib/api-routes/ekomi/types';
import { SxProps, Theme, useMediaQuery } from '@mui/material';

import { ListIcons, SellingPointsPosition } from './utils';
import { SellingPoints } from './SellingPoints/SellingPoints';
import { GuaranteeBadge } from './Badges/GuaranteeBadge';
import { LoanSelector } from 'components/LoanSelector';
import { Badges, BadgeVariants } from './Badges';
import { LegalPartner } from './LegalPartner';
import { FeaturedPanel } from './FeaturedPanel/FeaturedPanel';
import { BlackFridayBadge } from './Badges/BlackFridayBadge';
import { TopLightBanner } from 'components/ABTestVariations/AppRedirection/TopLightBanner';
import { Overlay } from 'components/ABTestVariations/AppRedirection/Overlay';
import { VideoHeroSection } from 'components/ABTestVariations/AppRedirection/VideoHeroSection';
import { getAppRedirectionExperimentStageConfig } from 'components/ABTestVariations/AppRedirection/helpers';

const HEADLINE_DEFAULT = 'Günstige Kredite - garantiert!';

export const Headline = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: 0,
  [theme.breakpoints.up('md')]: {
    lineHeight: '38px',
    fontWeight: 500,
  },
}));

const IntroText = styled(Typography)({
  lineHeight: 1.5,
  fontSize: '18px',
  letterSpacing: '0.3px',
  marginBottom: 0,
});

const HeadlineGridItem = styled(Grid)(({ theme }) => ({
  padding: '0',
  height: 'auto',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '1%',
  },
}));

const FeaturedPanelStyled = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  paddingLeft: 0,
}));

const getSellingPointsWithGuaranteeBadge = (
  listIcon: ListIcons,
  ...sellingPoints: (string | undefined)[]
) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={(theme) => theme.spacing(3)}
    >
      <GuaranteeBadge />
      <SellingPoints
        sellingPoints={sellingPoints.filter((sp) => sp) as string[]}
        listIcon={listIcon}
      />
    </Box>
  );
};

export type StageStyleOverrides = {
  headline?: SxProps;
  subline?: SxProps;
};

export type StageProps = Partial<
  any & {
    averageScore?: AverageScore | null;
    listIcon?: ListIcons;
    sellingPointsPosition?: SellingPointsPosition;
    styleOverrides?: StageStyleOverrides;
    showGuaranteeBadgeOnTop?: boolean;
    showBlackFridayBadge?: boolean;
    showTrustAndEkomi?: boolean;
    showFeaturedPanel?: boolean;
    additionalParams?: Record<string, unknown>;
    showTvBanner: boolean;
    isBulletPointsPositionRearranged?: boolean;
    isCreditCardCategoryEnabled?: boolean;
    appRedirectionVariation?: string | null;
    isAppContext?: boolean;
  }
>;

export const Stage: React.FC<StageProps> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const { sellingPointsPosition, listIcon = ListIcons.DOTS } = props;
  const hasSellingPointsUp =
    !isMobile || sellingPointsPosition === SellingPointsPosition.UP;

  const appRedirectionExperimentConfig = getAppRedirectionExperimentStageConfig(
    props.appRedirectionVariation,
  );

  return (
    <>
      {appRedirectionExperimentConfig.isTopLightBannerEnabled && (
        <TopLightBanner />
      )}
      <Container>
        <Grid container>
          {appRedirectionExperimentConfig.isOverlayEnabled && <Overlay />}
          <HeadlineGridItem
            item
            xs={props.showGuaranteeBadgeOnTop ? 8 : 12}
            md={6}
          >
            <Headline variant="h1" sx={props.styleOverrides?.headline}>
              {props.headline || HEADLINE_DEFAULT}
            </Headline>
            {props.subline && (
              <IntroText sx={props.styleOverrides?.subline}>
                {props.subline}
              </IntroText>
            )}
            {(!props.isBulletPointsPositionRearranged || !isMobile) &&
              (!isMobile || hasSellingPointsUp) && (
                <SellingPoints
                  sellingPoints={
                    [
                      props.sellingPoint1,
                      props.sellingPoint2,
                      props.sellingPoint3,
                    ].filter((sp) => sp) as string[]
                  }
                  listIcon={listIcon}
                />
              )}
            {!isMobile && props.showTrustAndEkomi && (
              <LegalPartner averageScore={props.averageScore} />
            )}
            {!isMobile && props.showFeaturedPanel && (
              <FeaturedPanelStyled>
                <FeaturedPanel />
              </FeaturedPanelStyled>
            )}
          </HeadlineGridItem>

          <Grid
            item
            xs={4}
            display={{
              xs: props.showBlackFridayBadge ? 'block' : 'none',
              md: 'none',
            }}
            sx={{ textAlign: 'center' }}
          >
            <BlackFridayBadge />
          </Grid>

          <Grid
            item
            xs={4}
            display={{
              xs:
                props.showGuaranteeBadgeOnTop && !props.showBlackFridayBadge
                  ? 'block'
                  : 'none',
              md: 'none',
            }}
            sx={{ textAlign: 'center' }}
          >
            <GuaranteeBadge placement={'bottom'} />
          </Grid>
          {props.isBulletPointsPositionRearranged && (
            <Grid
              item
              xs={12}
              md={6}
              display={{
                xs: props.showGuaranteeBadgeOnTop ? 'block' : 'none',
                md: 'none',
              }}
              sx={{ textAlign: 'center' }}
            >
              {(!isMobile || hasSellingPointsUp) && (
                <SellingPoints
                  sellingPoints={
                    [
                      props.sellingPoint1,
                      props.sellingPoint2,
                      props.sellingPoint3,
                    ].filter((sp) => sp) as string[]
                  }
                  listIcon={listIcon}
                />
              )}
            </Grid>
          )}

          {appRedirectionExperimentConfig.isVideoHeroSectionEnabled && (
            <VideoHeroSection />
          )}
          <Grid item xs={12} md={6}>
            <LoanSelector
              additionalParams={props.additionalParams}
              hideTvBanner={!props.showTvBanner}
              isCreditCardCategoryEnabled={props.isCreditCardCategoryEnabled}
              isSolarPanelCategoryEnabled={props.isSolarPanelCategoryEnabled}
              isAppContext={props.isAppContext}
              appRedirectionVariation={props.appRedirectionVariation}
              endIcon={appRedirectionExperimentConfig.endIcon}
              ctaText={appRedirectionExperimentConfig.ctaText}
            >
              <Badges
                variant={BadgeVariants.Hidden}
                isBlackFridayBadgeShown={props.showBlackFridayBadge}
              />
            </LoanSelector>
          </Grid>
          {isMobile && props.showTrustAndEkomi && (
            <Grid item xs={12}>
              <LegalPartner
                averageScore={props.averageScore}
                showBlackFridayBadge={props.showBlackFridayBadge}
              />
            </Grid>
          )}
          {isMobile && props.showFeaturedPanel && (
            <Grid item xs={12} sx={{ marginTop: '16px' }}>
              <FeaturedPanel
                isGuaranteeBadgeIncluded={props.showBlackFridayBadge}
              />
            </Grid>
          )}
          {isMobile &&
            props.sellingPointsPosition === SellingPointsPosition.DOWN && (
              <Grid item xs={12}>
                {getSellingPointsWithGuaranteeBadge(
                  listIcon,
                  props.sellingPoint1,
                  props.sellingPoint2,
                  props.sellingPoint3,
                )}
              </Grid>
            )}
        </Grid>
      </Container>
    </>
  );
};
