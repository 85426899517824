interface IPaasProps {
  advertisementId: string | undefined;
  affiliateId: number | undefined;
  amount: number;
  clientUuid: string;
  configurationId: string | undefined;
  formConfig?: string;
  isDDF?: boolean;
  purpose: string;
  refId?: string | undefined;
  subId?: string | undefined;
  resumeHash: string | undefined;
  term: number;
  vehiclePrice?: number | undefined;
}

interface IPaasSystemData {
  advertisementId: string | undefined;
  affiliateId: number | undefined;
  amount: number | undefined;
  clientUuid: string;
  configurationId: string | undefined;
  externalId: string | undefined;
  ipAddress: string | undefined;
  referer: string | undefined;
  refId: string | undefined;
  subId: string | undefined;
  userAgent: string | undefined;
  leadId: string | undefined;
}

interface IPaasLoanSelectionData {
  amount: number;
  term: number;
  purpose: string;
}

interface IPaasV2WithoutDebtorsData {
  data: {
    common: IPaasLoanSelectionData;
    system: IPaasSystemData;
  };
  resumeHash: string | undefined;
}

const DEFAULT_CONFIGURATION_ID = process.env.ADVERTISEMENT_ID;
const DEFAULT_AFFILIATE_ID = 200;

/**
 * We have a staged fallback if refid is present or not
 * @param advertisementId
 * @param refId
 * @returns
 */
export const getAdvertisementId = (
  advertisementId: string | undefined,
  refId: string | undefined,
) => {
  if (refId) return refId;
  if (advertisementId) return advertisementId;
  return process.env.ADVERTISEMENT_ID;
};

/**
 * We have a staged fallback if refid is present or not
 * @param affiliateId
 * @param refId
 * @returns
 */
export const getAffiliateId = (
  affiliateId: number | undefined,
  refId: string | undefined,
) => {
  if (refId) return undefined;
  if (affiliateId) return affiliateId;
  return DEFAULT_AFFILIATE_ID;
};

export const encodePaasData = (
  data: IPaasProps,
): IPaasV2WithoutDebtorsData | null => {
  try {
    const {
      amount,
      term,
      purpose,
      clientUuid,
      configurationId = DEFAULT_CONFIGURATION_ID,
      refId,
      subId
    } = data;

    const advertisementId = getAdvertisementId(data.advertisementId, refId);
    const affiliateId = getAffiliateId(data.affiliateId, refId);

    const common = {
      amount,
      term,
      purpose,
    };
    const system = {
      clientUuid,
      advertisementId,
      affiliateId,
      configurationId,
      refId,
      subId
    };
    const paasData = {
      data: { common, system },
      resumeHash: data.resumeHash,
    } as IPaasV2WithoutDebtorsData;

    return paasData;
  } catch (e) {
    console.error(e);
    return null;
  }
};
