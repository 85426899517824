import Image from 'next/image';
import { styled } from '@mui/material/styles';

import CreditBadge32Percent from 'public/assets/visuals/credit-badge-32percent.svg';
import CreditBadge35Percent from 'public/assets/visuals/credit-badge-35percent.png';
import CreditBadge35PercentNoIndex from 'public/assets/visuals/credit-badge-35percent-noindex.png';

export enum BadgeVariants {
  GreenSave32 = 'GreenSave32',
  GreenSave35 = 'GreenSave35',
  GreenSave35NoIndex = 'GreenSave35NoIndex',
  Hidden = 'Hidden',
}

const CircleBadgeWrapper = styled('div')`
  a {
    text-align: center;
  }
`;

const getBadgeSource = (variant: BadgeVariants) => {
  switch (variant) {
    case BadgeVariants.GreenSave32:
      return CreditBadge32Percent;
    case BadgeVariants.GreenSave35:
      return CreditBadge35Percent;
    case BadgeVariants.GreenSave35NoIndex:
      return CreditBadge35PercentNoIndex;
    default:
      return CreditBadge35PercentNoIndex;
  }
};

export const CircleBadge: React.FC<{
  variant: BadgeVariants;
  height?: number;
  width?: number;
}> = ({ variant, height = 85, width = 85 }) => {
  if (variant === BadgeVariants.Hidden) return null;

  return (
    <CircleBadgeWrapper>
      <a href="https://www.smava.de/kredit/aktionskredit">
        <Image
          src={getBadgeSource(variant)}
          priority
          alt="Smava Kredit Badge"
          height={height}
          width={width}
        />
      </a>
    </CircleBadgeWrapper>
  );
};
