import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const TileBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: 254,
    width: 254,
    height: 84,
    background: '#ffffff',
    border: '1px solid #e8e8e8',
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: `${theme.spacing()} ${theme.spacing(2)}`
}))

const Title = styled('div')(({ theme }) => ({
    fontSize: 12,
    lineHeight: '32px',
    color: theme.palette.grey[600],
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '1.3px'
}))

const TileImages = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    width: '100%'
}))

export type FeaturedTileProps = {
    title: string;
    children: React.ReactNode;
}

export const FeaturedTile: React.FC<FeaturedTileProps> = ({ title, children }) => {
    return (<TileBox>
        <Title>{title}</Title>
        <TileImages>
            {children}
        </TileImages>
    </TileBox>)
}
