import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import { ListIcons } from '../utils';
import { expandTextMacros } from 'lib/text-macros/textMacroHelper';

const StyledListItem = styled(ListItem)(({
    padding: '4px 0px',
    '& .MuiListItemText-primary': {
        margin: 0
    }
}))

export type SellingPointsProps = {
    sellingPoints?: string[];
    listIcon?: ListIcons;
}

export const SellingPoints: React.FC<SellingPointsProps> = ({ sellingPoints = [], listIcon = ListIcons.CHECK_MARKS }) => {
    return <List>
        {sellingPoints.map((sellingPoint, idx) => (
            <StyledListItem key={`sellingpoint_${idx}`} disableGutters disablePadding>
                <ListItemIcon sx={{
                    minWidth: 0,
                    marginRight: '10px'
                }}>
                    {listIcon === ListIcons.CHECK_MARKS ? <CheckIcon color='primary' /> : <CircleIcon sx={{
                        color: 'black',
                        fontSize: 5
                    }} />}
                </ListItemIcon>
                <ListItemText sx={{ marginBottom: '0px' }}>{expandTextMacros(sellingPoint)}</ListItemText>
            </StyledListItem>
        ))}
    </List>
}
