import type { Testimonial as TestimonialType } from 'lib/api-routes/ekomi/types';

import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';

import Testimonial from './Testimonial';
import { Slider } from 'components/Slider';

const Background = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
}));

const HeadingContainer = styled(Container)`
  padding: 0;
`;

const SliderContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

export interface TestimonialProps extends Partial<any> {
  testimonials?: TestimonialType[];
}

export const Testimonials = ({ testimonials, title }: TestimonialProps) => {
  const hasTestimonials = testimonials && testimonials.length !== 0;
  if (!title && !hasTestimonials) return null;

  return (
    <Background>
      <Box py={8}>
        {title && (
          <Box mb={4} px={2}>
            <HeadingContainer maxWidth="lg">
              <Typography variant="h2">{title}</Typography>
            </HeadingContainer>
          </Box>
        )}
        <SliderContainer maxWidth="lg">
          <Slider>
            {testimonials?.map((testimonial) => (
              <Testimonial key={testimonial.submitted} data={testimonial} />
            ))}
          </Slider>
        </SliderContainer>
      </Box>
    </Background>
  );
};
