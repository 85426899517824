import { useEffect, useMemo } from 'react';
import { createInstance, logOnlyEventDispatcher } from '@optimizely/react-sdk';
import { Config } from '@optimizely/optimizely-sdk';
import { UserInfo } from '@optimizely/react-sdk/dist/utils';
import { CookieBasedProfileService } from './CookieBasedProfileService';

export type OptimizelyPageProps = {
  datafile?: Pick<Config, 'datafile'>;
  user?: UserInfo;
  serializedVariationAssignments?: string;
};

export const useOptimizely = ({
  datafile,
  user,
  serializedVariationAssignments,
}: OptimizelyPageProps) => {
  // don't dispatch events to Optimizely results server directly.
  // We're using Segment Experiment Viewed event for that.
  const optimizely = useMemo(
    () =>
      createInstance({
        datafile,
        eventDispatcher: logOnlyEventDispatcher,
        userProfileService: new CookieBasedProfileService(
          serializedVariationAssignments ?? '',
        ),
      }),
    [datafile],
  );

  // Make optimizely globally accessible for segment
  useEffect(() => {
    if (process.env.ENABLE_OPTIMIZELY_SEGMENT_INTEGRATION === 'true') {
      window.optimizelyClientInstance = optimizely;
    }
  }, [optimizely]);
  // On client we manually need to update the user as OptimizelyProvider wouldn't realize the change
  useEffect(() => {
    user?.id && optimizely.setUser(user);
  }, [optimizely, user]);

  return optimizely;
}
