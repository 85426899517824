import { ReactSDKClient } from '@optimizely/react-sdk';
import { ABTestsConfig, ABTestKeys } from './flagConfigs';

export const forceVariation = (
  urlParams: URLSearchParams,
  optimizely: ReactSDKClient | null,
) => {
  if (!(process.env.RELEASE_STAGE === 'live')) {
    const forcedVariation = urlParams.get('forceVariation') || '';
    const forcedExperiment = urlParams.get('key') || '';
    if (forcedVariation && forcedExperiment) {
      const experimentKey: ABTestKeys = (<any>ABTestKeys)[forcedExperiment];
      if (
        experimentKey &&
        (ABTestsConfig[experimentKey] || []).indexOf(forcedVariation) !== -1
      ) {
        const optimizelyUserId = optimizely?.user.id || '';
        optimizely?.setForcedVariation(
          String(experimentKey),
          optimizelyUserId,
          forcedVariation,
        );
        console.info('Force Variation of AB test');
      } else {
        console.error(
          'Error: Please enter correct url parameters to see force variation',
        );
      }
    }
  }
};
