import fetcher from 'lib/fetcher';
import { stripUndefinedFromObject } from 'lib/json/stripUndefined';

// We allow undefined in here as we have a safeguard against that
type JSONValue =
  | string
  | number
  | boolean
  | undefined
  | { [x: string]: JSONValue }
  | Array<JSONValue>;

export type ILogLevel = 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace';

export async function log2net(
  eventName: string,
  rawPayload: JSONValue,
  logLevel: ILogLevel = 'info',
) {
  const payload = stripUndefinedFromObject(rawPayload as Record<string, any>);
  const response = await fetcher('/api/net-log/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ eventName, payload, logLevel }),
  });
  return response;
}
