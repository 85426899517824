// @TODO: remove this file if homepage is fully editable in contentful
export const steps = [
  {
    metadata: {
      tags: [],
    },
    sys: {
      id: '30qJVYoORB8hZxd1B7WPkB',
      type: 'Entry',
      createdAt: '2021-11-24T15:53:53.427Z',
      updatedAt: '2021-11-24T16:09:45.989Z',
      contentType: {
        sys: {
          id: 'component_step',
          linkType: 'ContentType',
          type: 'Link',
        },
      },
      locale: 'de-DE',
    },
    fields: {
      name: 'Kreditanfrage',
      image: {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'vh9g3jzzo9qx',
            },
          },
          contentType: {
            sys: {
              id: 'component_testimonials',
              linkType: 'ContentType',
              type: 'Link',
            },
          },
          id: '42NbP3GmlKIPLrsiVFDyiV',
          type: 'Asset',
          createdAt: '2021-11-24T15:53:53.263Z',
          updatedAt: '2021-11-24T16:09:45.816Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 3,
          locale: 'de-DE',
        },
        fields: {
          title: 'free-inquiry.svg',
          file: {
            url: '/assets/icons/free-inquiry.svg',
            details: {
              size: 3732,
              image: {
                width: 128,
                height: 128,
              },
            },
            fileName: 'free-inquiry.svg',
            contentType: 'image/svg+xml',
          },
          description: '',
        },
      },
      text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Kostenlose Kreditanfrage',
                nodeType: 'text',
              },
            ],
            nodeType: 'heading-3',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Zuerst stellen Sie unverbindlich und kostenlos ihre Kreditanfrage ganz bequem von zu Hause aus.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
  },
  {
    metadata: {
      tags: [],
    },
    sys: {
      id: '3qjk32soHOOsFsJCFhgVOb',
      type: 'Entry',
      createdAt: '2021-11-24T15:53:53.445Z',
      updatedAt: '2021-11-24T16:09:46.012Z',
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'component_step',
        },
      },
      locale: 'de-DE',
    },
    fields: {
      name: 'Angebote erhalten',
      image: {
        metadata: {
          tags: [],
        },
        sys: {
          contentType: {
            sys: {
              id: 'component_testimonials',
              linkType: 'ContentType',
              type: 'Link',
            },
          },
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'vh9g3jzzo9qx',
            },
          },
          id: 'VMJv1k7oRDaWDBHEosldv',
          type: 'Asset',
          createdAt: '2021-11-24T15:53:53.269Z',
          updatedAt: '2021-11-24T16:09:45.822Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 3,
          locale: 'de-DE',
        },
        fields: {
          title: 'get-offers.svg',
          file: {
            url: '/assets/icons/get-offers.svg',
            details: {
              size: 5348,
              image: {
                width: 67,
                height: 56,
              },
            },
            fileName: 'get-offers.svg',
            contentType: 'image/svg+xml',
          },
          description: '',
        },
      },
      text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Angebote erhalten',
                nodeType: 'text',
              },
            ],
            nodeType: 'heading-3',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Sie erhalten Kreditangebote von verschiedenen Banken. Den passenden Kredit können Sie direkt online beantragen.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: '',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
  },
  {
    metadata: {
      tags: [],
    },
    sys: {
      id: '1haSknFRKuhaZxmPJc51fn',
      type: 'Entry',
      createdAt: '2021-11-24T15:53:53.463Z',
      updatedAt: '2021-11-24T16:09:46.039Z',
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: 'component_step',
        },
      },
      locale: 'de-DE',
    },
    fields: {
      name: 'Kreditantrag abschließen',
      image: {
        metadata: {
          tags: [],
        },
        sys: {
          contentType: {
            sys: {
              id: 'component_testimonials',
              linkType: 'ContentType',
              type: 'Link',
            },
          },
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'vh9g3jzzo9qx',
            },
          },
          id: '3v7t0KQlipg6i6H4BtIKmH',
          type: 'Asset',
          createdAt: '2021-11-24T15:53:53.274Z',
          updatedAt: '2021-11-24T16:09:45.828Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 3,
          locale: 'de-DE',
        },
        fields: {
          title: 'close-application.svg',
          file: {
            url: '/assets/icons/close-application.svg',
            details: {
              size: 9060,
              image: {
                width: 72,
                height: 72,
              },
            },
            fileName: 'close-application.svg',
            contentType: 'image/svg+xml',
          },
          description: '',
        },
      },
      text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Kreditantrag abschließen',
                nodeType: 'text',
              },
            ],
            nodeType: 'heading-3',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Sind Ihre Unterlagen vollständig und alle Voraussetzungen erfüllt, erhalten Sie innerhalb kürzester Zeit Ihr Geld.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
  },
];
