import { parse, serialize, CookieSerializeOptions } from 'cookie';
import {
  DEFAULT_COOKIE_OPTIONS,
  COOKIE_BASE_URL,
  ONE_YEAR_IN_SECONDS,
} from 'lib/constants/cookies';

export const getCookie = (name: string): string | undefined => {
  if (!name) return;
  if (typeof document !== 'undefined' && navigator && navigator.cookieEnabled) {
    const cookies = parse(document.cookie);
    if (cookies[name]) {
      return cookies[name];
    }
  }
};

export const setCookie = (
  cname: string,
  cvalue: string,
  options: CookieSerializeOptions = {},
) => {
  if (typeof document !== 'undefined' && navigator && navigator.cookieEnabled) {
    const mergedOptions: CookieSerializeOptions = {
      ...DEFAULT_COOKIE_OPTIONS,
      maxAge: ONE_YEAR_IN_SECONDS,
      ...options,
    };

    document.cookie = serialize(cname, cvalue, mergedOptions);

    // if on localhost, also write the cookie on localhost to enable
    // hybrid work with shared plus localhost
    if (window.location.hostname === 'localhost') {
      const localMergedOptions = { ...mergedOptions };
      delete localMergedOptions.domain;
      document.cookie = serialize(cname, cvalue, localMergedOptions);
    }
  }
};

export const eraseCookie = (cname: string) => {
  const deletedValue = '';

  const options = {
    maxAge: -1,
    domain: COOKIE_BASE_URL || undefined,
    path: '/',
  };

  document.cookie = serialize(cname, deletedValue, options);
  // if on localhost, also write the cookie on localhost to enable
  // hybrid work with shared plus localhost
  if (window.location.hostname === 'localhost') {
    const localOptions = { ...options };
    delete localOptions.domain;
    document.cookie = serialize(cname, deletedValue, localOptions);
  }

  console.info('Deleted Cookie', cname, COOKIE_BASE_URL);
};
