import Bugsnag from '@bugsnag/js';
import fetchWithTimeout from 'lib/fetcher';

export const postPaasData = async (data: any) => {
  const paasUrl = process.env.REGISTRATION_PAAS_URL;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetchWithTimeout(`${paasUrl}/prefill`, {
      credentials: 'include',
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });

    if (!response) {
      throw new Error('No response while trying to post data to PAAS');
    }

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(
        `Received error response while trying to post data to PAAS. Status: ${response.status}`,
      );
    }

    return response;
  } catch (error) {
    Bugsnag.notify(error as Error, (errorEvent) => {
      errorEvent.context = 'Error while trying to post data to PAAS';
    });
    return false;
  }
};
