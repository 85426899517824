import Image from 'next/image';
import { styled } from '@mui/material/styles';
import Kredit2DayLogo from 'public/assets/partner-banks/kredit2day.svg';
import WunschkreditLogo from 'public/assets/partner-banks/smava-wunschkredit.svg';
import MobileDeLogo from 'public/assets/visuals/mobile_de.svg';
import { FeaturedTile } from '../FeaturedTile/FeaturedTile';
import { GuaranteeBadge } from '../Badges/GuaranteeBadge';

const BadgeWrapper = styled('div')`
  img {
    width: 85px;
    height: 85px;
  }
`;

const FeaturedPanelStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  overflowY: 'hidden',
  gap: theme.spacing(2),
  height: '100px',

  // hide scrollbar but keep functionality
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

interface FeaturedPanel {
  isGuaranteeBadgeIncluded?: boolean | undefined;
}

export const FeaturedPanel: React.FC<FeaturedPanel> = ({
  isGuaranteeBadgeIncluded,
}) => (
  <FeaturedPanelStyled>
    {isGuaranteeBadgeIncluded && (
      <BadgeWrapper>
        <GuaranteeBadge placement={'top'} />
      </BadgeWrapper>
    )}
    <FeaturedTile title="Exklusive Kreditangebote">
      <Image src={WunschkreditLogo} alt="smava Wunschkredit" width={100} />
      <Image src={Kredit2DayLogo} alt="Kredit2Day Logo" width={110} />
    </FeaturedTile>
    <FeaturedTile title="Kreditpartner von">
      <Image src={MobileDeLogo} alt="mobile.de Logo" />
    </FeaturedTile>
  </FeaturedPanelStyled>
);
