import Image from 'next/image';
import CreditBadgeBlackFriday from 'public/assets/visuals/credit-badge-black-friday.svg';

export const BlackFridayBadge: React.FC = () => (
  <Image
    src={CreditBadgeBlackFriday}
    priority
    alt="Smava Kredit Black Friday"
  />
);
