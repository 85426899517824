import { Theme } from '@mui/material';
import { StageStyleOverrides } from '../Stage';

// @deprecated @TODO please remove it and use style directly where needed
export const getStyleOverrides = (theme: Theme): StageStyleOverrides => ({
  headline: {
    fontSize: 26,
    fontWeight: 500,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      lineHeight: '64px',
      fontWeight: 700,
      width: '90%',
    },
  },
  subline: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
});
