import { InputNames } from 'contexts/LoanSelectionContext';
import { TrackingEvent } from './events';

export const track = (
  trackingEvent: TrackingEvent,
  properties: Record<string, unknown> = {},
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void,
) => {
  window.analytics?.track(
    trackingEvent,
    {
      ...properties,
      tenant: 'smava',
      path: window.location.pathname,
      team: 'catapult',
    },
    options,
    callback,
  );
};

export const trackButtonClick = (
  trackingEvent: TrackingEvent,
  properties: Record<string, unknown> = {},
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void,
) => {
  track(trackingEvent, { ...properties, action: 'click' }, options, callback);
};

const getTrackingEventByInputName = (inputName: InputNames): TrackingEvent => {
  switch (inputName) {
    case InputNames.Category:
      return TrackingEvent.LOANSELECTOR_PURPOSE_ONINTERACT;
    case InputNames.Amount:
      return TrackingEvent.LOANSELECTOR_AMOUNT_ONINTERACT;
    case InputNames.Duration:
      return TrackingEvent.LOANSELECTOR_TERM_ONINTERACT;
    case InputNames.InitialPayment:
      return TrackingEvent.LOANSELECTOR_INITIALPAYMENT_ONINTERACT;
    case InputNames.VehiclePrice:
      return TrackingEvent.LOANSELECTOR_VEHICLEPRICE_ONINTERACT;
  }
};

export const trackLoanSelectorChange = (
  inputName: InputNames,
  value: string | number,
) => {
  const trackingEvent: TrackingEvent = getTrackingEventByInputName(inputName);
  if (trackingEvent) {
    track(trackingEvent, { action: 'change', value });
  }
};

export const trackPageView = (properties = {}) => {
  window.analytics?.page({
    ...properties,
    team: 'catapult',
    tenant: 'smava',
  });
};
